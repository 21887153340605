export const data = [
  {
    name: "blog",
    emoji: "✍️",
    description: "beautiful biased bullshit",
  },
  {
    name: "links",
    emoji: "🔗",
    description: "max platforms, min followers",
  },
  {
    name: "gadgets",
    emoji: "🛠️",
    description: "barely-functional tools",
  },
  {
    name: "gallery",
    emoji: "🖼️",
    description: "poorly-pictured photos",
  },
];
